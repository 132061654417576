<template>
  <div>
    <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="members"
      @uploadFromCSV="uploadFromCSV"
    />
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="modalMessage"
      :title="modalTitle"
      v-on:toggle-modal="isShowModal = false"
      :isSuccessButton="false"
    />
  </div>
</template>
<script>
  import { members } from '@/config/descriptions/members';
  import { membersTexts } from '@/config/pageTexts/members.json';
  import Modal from '@/shared/UI/Modal';
  import axios from 'axios';

  export default {
    name: 'MembersTable',
    components: { Modal },
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'members/members',
            fields: 'members/fields',
            sortableFields: 'members/sortableFields',
            pages: 'members/pages',
            loading: 'members/loading',
            resultCount: 'members/resultCount',
            totalRecords: 'members/totalRecords',
            originalFields: 'members/originalFields',
            searchableAllFields: 'members/searchableAllFields',
            success: 'members/success',
            message: 'members/message',
          },
          actions: {
            fields: 'members/handleFields',
            delete: 'members/handleDeleteMembers',
            reset: 'members/handleClearFilter',
            query: 'members/handleGetMembersByQuery',
          },
        },
        settings: {
          describe: { ...members },
          page: { ...membersTexts },
        },
        route: {
          create: 'CreateMember',
          edit: 'EditMember',
          preview: 'PreviewMember',
        },
        isShowModal: false,
        modalMessage: '',
        modalTitle: '',
      };
    },
    methods: {
      async uploadFromCSV(file) {
        const token = localStorage.getItem('vue-token')

        const hostname = window.location.hostname;
        const isStagingDev = hostname.includes('127.0.0.1');
        const isStaging = hostname.split('.').includes('staging');

        const baseUrl = isStagingDev || isStaging
          ? `https://api.staging.ziqni.io`
          : `https://api.ziqni.com`;

        const path = `${baseUrl}/upload-members-csv-file`;

        const formData = new FormData()
        formData.append('membersFile', file, file.name)

        await axios.post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.data.errors.length) {
              this.modalTitle = this.settings.page.csvPopupErrorTitle;
              this.modalMessage = this.settings.page.csvPopupErrorMessage;
              this.isShowModal = true;
            }

            if (result.data.results && result.data.results.length) {
              if (result.data.results[0].result === 'Updated') {
                this.modalTitle = this.settings.page.csvPopupTitle;
                this.modalMessage = this.settings.page.csvRecordsUpdated + ' ' + result.data.meta.totalRecords;
              } else {
                this.modalTitle = this.settings.page.csvPopupTitle;
                this.modalMessage = this.settings.page.csvRecordsCreated + ' ' + result.data.meta.totalRecords;
              }
              this.isShowModal = true;
            }
          })
          .catch(error => console.error(error))
      },
    }
  };
</script>
